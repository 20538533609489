/**
 * Types for product components.
 * FIXME: @Jonas: implement annotation for each type
 */
var ProductComponentTypeEnum;
(function (ProductComponentTypeEnum) {
    ProductComponentTypeEnum["ABSTRACT"] = "ABSTRACT";
    ProductComponentTypeEnum["INTRODUCTION"] = "INTRODUCTION";
    ProductComponentTypeEnum["PRODUCT"] = "PRODUCT";
})(ProductComponentTypeEnum || (ProductComponentTypeEnum = {}));
var RenewsProductEnum;
(function (RenewsProductEnum) {
    RenewsProductEnum["DigitalSignage"] = "DIGITAL_SIGNAGE";
    RenewsProductEnum["OneMirror"] = "ONEMIRROR";
    RenewsProductEnum["ShowroomPresenter"] = "SHOWROOM_PRESENTER";
})(RenewsProductEnum || (RenewsProductEnum = {}));
var Windows11UpgradeProductIndentifierEnum;
(function (Windows11UpgradeProductIndentifierEnum) {
    Windows11UpgradeProductIndentifierEnum["Windows11Upgrade"] = "WINDOWS11_UPGRADE";
    Windows11UpgradeProductIndentifierEnum["Windows11UpgradeHardware"] = "WINDOWS11_UPGRADE_HARDWARE";
    Windows11UpgradeProductIndentifierEnum["Windows11UpgradeHardwareMI"] = "WINDOWS11_UPGRADE_HARDWARE_MI";
})(Windows11UpgradeProductIndentifierEnum || (Windows11UpgradeProductIndentifierEnum = {}));
export { ProductComponentTypeEnum as ProductComponentType, RenewsProductEnum as RenewsProduct, Windows11UpgradeProductIndentifierEnum as Windows11UpgradeProductIndentifier, };
